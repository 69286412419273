<template>
    <div class="children-attribute">
        <img src="../../../assets/imgs/pc/children/img_shenz@2x.png" alt="兒童身高、體重、頭圍、胸圍介紹">
        <ul class="children-attribute-tabs">
            <router-link to="/children/height" class="children-attribute-tab">身高</router-link>
            <router-link to="/children/weight" class="children-attribute-tab">體重</router-link>
            <router-link to="/children/bmi" class="children-attribute-tab current">BMI</router-link>
            <router-link to="/children/head_circ" class="children-attribute-tab">頭圍</router-link>
            <router-link to="/children/chest_circ" class="children-attribute-tab">胸圍</router-link>
        </ul>
        <div class="children-attribute-content">
            <div style="width: 0px;height: 0px; position: relative; overflow: hidden;"><h1>小孩(兒童)怎麼算肥胖，BMI範圍</h1></div>
            <p class="children-attribute-item current">
                <span>兒童BMI</span>也稱為年齡別BMI，因為不同年齡有著不同的體重分級。體重指數(BMI)的計算方法： 體重(公斤)/身高的平方(米的平方)，例如：體重62kg、身高145cm、年齡10歲的男孩，BMI=62/(1.45*1.45)=29.49(kg/m2)，查表：肥胖，應合理飲食加運動控制，禁止饑餓半饑餓療法，原則上不採用藥物和手術療法。
            </p>
        </div>
        <div class="chart">
            <div class="title">2～18歲男孩BMI參照值</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div>
                    <div class="column">過輕</div>
                    <div class="column">過重</div>
                    <div class="column">肥胖</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">2歲</div><div class="column">15.2</div><div class="column">17.7</div><div class="column">19</div></div>
                    <div class="row"><div class="column">3歲</div><div class="column">14.8</div><div class="column">17.7</div><div class="column">19.1</div></div>
                    <div class="row"><div class="column">4歲</div><div class="column">14.4</div><div class="column">17.7</div><div class="column">19.3</div></div>
                    <div class="row"><div class="column">5歲</div><div class="column">14</div><div class="column">17.7</div><div class="column">19.4</div></div>
                    <div class="row"><div class="column">6歲</div><div class="column">13.9</div><div class="column">17.9</div><div class="column">19.7</div></div>
                    <div class="row"><div class="column">7歲</div><div class="column">14.7</div><div class="column">18.6</div><div class="column">21.2</div></div>
                    <div class="row"><div class="column">8歲</div><div class="column">15</div><div class="column">19.3</div><div class="column">22</div></div>
                    <div class="row"><div class="column">9歲</div><div class="column">15.2</div><div class="column">19.7</div><div class="column">22.5</div></div>
                    <div class="row"><div class="column">10歲</div><div class="column">15.4</div><div class="column">20.3</div><div class="column">22.9</div></div>
                    <div class="row"><div class="column">11歲</div><div class="column">15.8</div><div class="column">21</div><div class="column">23.5</div></div>
                    <div class="row"><div class="column">12歲</div><div class="column">16.4</div><div class="column">21.5</div><div class="column">24.2</div></div>
                    <div class="row"><div class="column">13歲</div><div class="column">17</div><div class="column">22.2</div><div class="column">24.8</div></div>
                    <div class="row"><div class="column">14歲</div><div class="column">17.6</div><div class="column">22.7</div><div class="column">25.2</div></div>
                    <div class="row"><div class="column">15歲</div><div class="column">18.2</div><div class="column">23.1</div><div class="column">25.5</div></div>
                    <div class="row"><div class="column">16歲</div><div class="column">18.6</div><div class="column">23.4</div><div class="column">25.6</div></div>
                    <div class="row"><div class="column">17歲</div><div class="column">19</div><div class="column">23.6</div><div class="column">25.6</div></div>
                    <div class="row"><div class="column">18歲</div><div class="column">19.2</div><div class="column">23.7</div><div class="column">25.6</div></div>
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="title">2～18歲女孩BMI參照值</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div>
                    <div class="column">過輕</div>
                    <div class="column">過重</div>
                    <div class="column">肥胖</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">2歲</div><div class="column">14.9</div><div class="column">17.3</div><div class="column">18.3</div></div>
                    <div class="row"><div class="column">3歲</div><div class="column">14.5</div><div class="column">17.2</div><div class="column">18.5</div></div>
                    <div class="row"><div class="column">4歲</div><div class="column">14.2</div><div class="column">17.1</div><div class="column">18.6</div></div>
                    <div class="row"><div class="column">5歲</div><div class="column">13.9</div><div class="column">17.1</div><div class="column">18.9</div></div>
                    <div class="row"><div class="column">6歲</div><div class="column">13.6</div><div class="column">17.2</div><div class="column">19.1</div></div>
                    <div class="row"><div class="column">7歲</div><div class="column">14.4</div><div class="column">18</div><div class="column">20.3</div></div>
                    <div class="row"><div class="column">8歲</div><div class="column">14.6</div><div class="column">18.8</div><div class="column">21</div></div>
                    <div class="row"><div class="column">9歲</div><div class="column">14.9</div><div class="column">19.3</div><div class="column">21.6</div></div>
                    <div class="row"><div class="column">10歲</div><div class="column">15.2</div><div class="column">20.1</div><div class="column">22.3</div></div>
                    <div class="row"><div class="column">11歲</div><div class="column">15.8</div><div class="column">20.9</div><div class="column">23.1</div></div>
                    <div class="row"><div class="column">12歲</div><div class="column">16.4</div><div class="column">21.6</div><div class="column">23.9</div></div>
                    <div class="row"><div class="column">13歲</div><div class="column">17</div><div class="column">22.2</div><div class="column">24.6</div></div>
                    <div class="row"><div class="column">14歲</div><div class="column">17.6</div><div class="column">22.7</div><div class="column">25.1</div></div>
                    <div class="row"><div class="column">15歲</div><div class="column">18</div><div class="column">22.7</div><div class="column">25.3</div></div>
                    <div class="row"><div class="column">16歲</div><div class="column">18.2</div><div class="column">22.7</div><div class="column">25.3</div></div>
                    <div class="row"><div class="column">17歲</div><div class="column">18.3</div><div class="column">22.7</div><div class="column">25.3</div></div>
                    <div class="row"><div class="column">18歲</div><div class="column">18.3</div><div class="column">22.7</div><div class="column">25.3</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        created: function(){
            setTimeout(() => {
                window.scrollTo(0, 960)    
            }, 0);
        }
    }
</script>

<style lang="scss" scoped>
    .children-attribute {
        width: 1000px;
        margin: 0px auto;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .children-attribute > img {
        position: absolute;
        right: 0px;
        top: 50px;
        width: 440px;
        height: 468px;
    }

    .children-attribute > .children-attribute-n {
        display: inline-block;
        width: 52px;
        height: 57px;
        position: absolute;
        left: 0px;
        top: 62px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/children/img_shuye@2x.png);
        background-size: 100% 100%;
        font-size: 30px;
        color: #36C4D0;
        font-weight: bold;
        box-sizing: border-box;
        line-height: 24px;
        padding: 10px 0px 0px 11px;
    }

    .children-attribute > .children-attribute-tabs {
        margin-top: 90px;
        height: 34px;
        display: block;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab {
        height: 34px;
        font-size: 18px;
        color: #231F20;
        font-weight: bold;
        display: inline-block;
        margin-right: 48px;
        cursor: pointer;
        user-select: none;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current {
        color: #36C4D0;
        position: relative;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 4px;
        width: 12px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }


    .children-attribute > .children-attribute-content {
        position: relative;
        height: 317px;
        width: 750px;
        overflow: hidden;
        margin-top: 89px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item {
        width: 750px;
        position: absolute;
        left: 750px;
        top: 0px;
        width: 750px;
        justify-content: space-between;
        background-color: rgba(239, 247, 248, 0.6);
        padding: 21px 18px 31px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #636363;
        line-height: 24px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item.current {
        left: 0px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item::first-line {
        line-height: 40px;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item > span {
        display: inline-block;
        margin-left: 33px;
        font-size: 24px;
        line-height: 24px;
        color: #231F20;
        font-weight: bold;
    }

    .children-attribute > .children-attribute-content > .children-attribute-item > .children-attribute-item-l {
        margin-top: 41px;
        margin-top: 29px;
        display: inline-block;
        height: 21px;
        line-height: 21px;
        font-size: 18px;
        color: #FF8000;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 49px;
    }

    .chart {
        margin-bottom: 20px;
    }

    .chart,
    .chart > .table {
        display: flex;
        flex-direction: column;
    }

    .chart > .title {
        height: 77px;
        line-height: 77px;
        color: #242020;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        padding-left: 24px;
        box-sizing: border-box;
    }

    .chart > .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/children/img_copy@2x.png);;
    }

    .chart > .table > .content > .row,
    .chart > .table > .head {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #EEF9FA;
        height: 30px;
        align-items: center;
    }

    .chart > .table > .head {
        border-bottom: 1px solid #CEE5E7;
    }

    .chart:last-child {
        margin-bottom: 70px;
    }
    
    .chart:last-child > .table > .head {
        background-color: #FFE7E7;
        border-bottom: 1px solid #E8C5C5;
    }


    .chart > .table > .content > .row {
        background-color: #fff;
    }

    .chart > .table > .content > .row:nth-child(odd) {
        background-color: #F6F7F8;
    }

    .chart > .table > .head > .column,
    .chart > .table > .content > .row > .column {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #646464;
    }

    .chart > .table > .head > .column {
        color: #242020;
        font-size: 14px;
        font-weight: bold;
    }
</style>
